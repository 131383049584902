.header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 17px;
  width: 100%;
}

.back {
  position: absolute;
}

.title {
  flex-grow: 1;
}
